.login-area {
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* min-height: 60vh; */
}
.login-btn {
    font-size: 18px;
    padding: 10px 24px;
    color: #F63E7B;
    background-color: #F5F5F5;
    border: 1px solid #F63E7B;
    border-radius: 28px;
}
.login-btn:hover {
    color: #FFF;
    border: 1px solid;
    background-color: #F63E7B;
}
.user-toggle {
    color: #F63E7B;
}
.user-toggle:hover {
    color: #F63E7B;
    background-color: #E5E5E5;
    text-decoration: none;
}